#hero{
    width: 100vw;
    height: 90vh;
    min-height: 750px;
    background-image: url('../Assets/KEYBOARD.webp');
    background-position: center;
    background-size: cover;
    padding: 100px 2rem;
    position: relative;
}

#hero-overlay{
    background: linear-gradient(to bottom, rgba(5, 5, 33, 0.7), rgba(1, 1, 11, 1));
    width: 100vw;
    height: 90vh;
    min-height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 100px 2rem;

}

#hero h2, #hero h1{
    color: #dfdfdf;
    font-size: 82px;
    margin: 0;
    font-family: 'Criteria';
    text-transform: uppercase;
    text-align: center;

}

#hero h1{
    font-size: 96px;
}

@media only screen and (max-width: 600px) {
    #hero h2{
        font-size: 48px;
    }

    #hero h2:first-child{
        margin-top: 75px;
    }
}