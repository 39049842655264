#portfolio-body{
    width: 100vw;
    height: 100vh;
    margin: 0;
    overflow: hidden;
}

#portfolio-track > .portfolio-item{
    min-width: 40vmin;
    min-height: 56vmin;
    background-position: 100% center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.portfolio-item >img{
    width: 30%;
    min-width: 75px;
    z-index: 2;
}

#music-logo{
    min-width: 100px;
}



#portfolio-track > .portfolio-item-open{
    min-width: 100vw;
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    z-index: 980;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 5s;
}

#tequila{
    background-image: url('https://images.unsplash.com/photo-1634130132261-3bdf61051454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80');
}
#music{
    background-image: url('https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80');
}
#care{
    background-image: url('https://images.unsplash.com/photo-1587556930799-8dca6fad6d41?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80');
}
#rota{
    background-image: url('https://images.unsplash.com/photo-1435527173128-983b87201f4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2367&q=80');
}
#dog{
    background-image: url('https://images.unsplash.com/photo-1506242395783-cec2bda110e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80');
}



#portfolio-track{
    display: flex;
    gap: 4vmin;
    position: fixed;
    left: 50%;
    top: 20%;
    user-select: none;
    width: auto;
    overflow: hidden;
    min-height: 500px;
}
#portfolio-track-open{
    display: flex;
    gap: 4vmin;
    position: absolute;
    left: 0;
    top: 0;
    user-select: none;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

#fade{
    position: sticky;
    z-index: 999;
    left: 0;
    width: 150px;
    height: 100%;
    background-image: linear-gradient(to left, 
        rgba(250, 250, 250, 0), 
        rgba(250, 250, 250, 1)
    100%);
    margin-right: 50px;
}

#portfolio-title{
    transition: transform 1s;
    transform: scaleY(0);
    width: 40%;
    max-height: 80vh;
    overflow: scroll;
    margin-left: 4rem;
    margin-top: 8rem;
    position: fixed;
    color: #222222;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.gradient{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(17,17,17,0.4);
}

.work-container{
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: top;
    align-items: top;
    padding: 1rem;
}

.work-container > img{
    height: 50px;
    align-self: center;
    margin-bottom: 1rem;
}

.work-container > h3{
   width: 100%;
   margin: 0;
}

.work-container > p{
    font-size: 14px;
 }


.scroll-icon {
	position: fixed;
    bottom: 3rem;
    left: calc(50% - 20px);
	margin: 2em 0;
	width: 40px;
	height: 70px;
	border: 3px solid #d3d3d3;
	border-radius: 2em;
}

.scroll-icon.ex-3 .wheel {
	position: absolute;
	left: 50%;
	top: 25%;
	width: .6em;
	height: .6em;
	background-color: #d3d3d3;
	transform: translate(-50%, 0);
	border-radius: .6em;
	animation: ex-3-wheel 1s ease-in-out infinite;
}
.scroll-icon.ex-3 .arrow {
	position: absolute;
	left: 50%;
	width: .4em;
	height: 1em;
	transform: translateX(-50%);
	opacity: .3;
	animation: ex-3-wheel-opacity 1s ease-in-out infinite;
}
.scroll-icon.ex-3 .arrow::before,
.scroll-icon.ex-3 .arrow::after {
	content: '';
	display: block;
	width: .4em;
	height: .4em;
	border-left: 1px solid #bebebe;
	border-top: 1px solid #bebebe;
	box-sizing: border-box;
}
.scroll-icon.ex-3 .arrow.up {
	top: 10%;
}
.scroll-icon.ex-3 .arrow.up::before,
.scroll-icon.ex-3 .arrow.up::after {
	transform: rotate(45deg);
}
.scroll-icon.ex-3 .arrow.down {
	top: 45%;
}
.scroll-icon.ex-3 .arrow.down::before,
.scroll-icon.ex-3 .arrow.down::after {
	transform: rotate(-135deg);
}
@keyframes ex-3-wheel {
	0%, 100% {
		transform: translate(-50%, 0);
	}
	50% {
		transform: translate(-50%, 50%);
	}
}
@keyframes ex-3-wheel-opacity {
	0%, 100% {
		opacity: .5;
	}
	50% {
		opacity: .2;
	}
}

@media only screen and (max-width: 1250px) {
    .work-container{
        width: 100%;
    }
    .work-container > h3{
        margin: 0;
     }
}

@media only screen and (max-width: 600px) {
    .scroll-icon{
        display: none;
    }

    #portfolio-track > .portfolio-item{
        min-width: 60vmin;
        height: 60vh;
        background-position: center
    }
    #portfolio-title{
        display: none;
    }
    #portfolio-track{
        left: 5%;
        overflow: scroll;
        width: calc(100vw - 5%)
    }
    #portfolio-body{
        overflow: scroll;
    }
}

@media only screen and (max-height: 820px) {
    .scroll-icon{
        bottom: 0px;
    }

}