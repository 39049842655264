#ready{
    width: 100vw;
    height: 90vh;
    min-height: 700px;
    background-image: url('../Assets/ready.webp');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

#ready h2{
    color: white;
    margin: 0;
    font-size: 82px;
    font-family: Criteria;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    #ready h2{
        font-size: 56px;
    }
}