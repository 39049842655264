#how-we-do-it{
    background-color: #1e1e1e;
    width: 100vw;
    min-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

#slider{
    min-height: 300px;
    width: calc(100vw - 2rem);
    max-width: 1300px;
    display: flex;
    overflow: scroll;

}

.slide{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #dfdfdf;
    height: 100%;
    width: 30vw;
    margin: 2rem;
    flex-shrink: 0;
}

.step-header{

    font-weight: 900;
    font-family: 'Criteria';
    
}

.slide p:first-of-type{
    text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
    #how-we-do-it{
        font-size: 56px;
    }

    .slide{
        width: 90%;
        align-items: baseline;
    }
}