#contact{
    display: flex;
    flex-direction: column;
    cursor: default;
    margin: 1rem 2rem;
    z-index: 999;
  }

  
  #contact > form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  
  #contact > form > div.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-wb57ya-MuiFormControl-root-MuiTextField-root{
    width: 100%;
  }
  
  #lets-chat{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  #lets-chat > h2{
    margin: 1rem 0 0 0 ;
    
  }
  #lets-chat > p{
    color: #1e1e1e;
    font-size:18px;
  }

  .form-input, textarea{
    width: 100%;
    margin: 5px;
    height: 50px;
    padding-left: 1rem;
    font-size: 16px;
    border-image-source: linear-gradient(45deg, rgba(250, 250, 250, 0), rgba(17, 16, 17, 1));
    border-style: solid;
    border-image-slice: 1;
    border-width: 1px;

  }

  textarea{
    height: 200px;
    padding-top: 1rem;
    resize: none;
  }

  @media only screen and (max-width: 600px) {
    #lets-chat > p{
      font-size:12px;
    }
    #lets-chat{
      padding-top: 0;
    }
    #contact{
      margin: 0;
    }
}